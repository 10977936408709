// ${{pmt}}
// ${{p}}

const runtime = typeof document === 'object';

function px(opts, cb) {
  const e = document.createElement('script');
  for (key in opts) {
    if (key !== 'textContent' && key !== 'top') {
      e.setAttribute(key, opts[key]);
    }
  }
  const hasAlready = !!document.querySelector(`[data-px="${opts['data-px']}"]`);
  e.onreadystatechange = function () {
    if (this.readyState === 'complete' || this.readyState === 'loaded') {
      if (typeof cb === 'function') {
        cb();
      }
    }
  };
  if (opts.textContent) {
    e.textContent = opts.textContent;
    if (typeof cb === 'function') {
      setTimeout(() => {
        cb();
      }, 1000);
    }
  }
  e.onload = cb;
  if (opts.dnt) {
    e.removeAttribute('dnt', null);
    if (
      parseInt(navigator.doNotTrack, 10) === 1 ||
      parseInt(window.doNotTrack, 10) === 1 ||
      parseInt(navigator.msDoNotTrack, 10) === 1 ||
      navigator.doNotTrack === 'yes'
    ) {
      return -1;
    }
  }
  if (opts.top) {
    const head = document.getElementsByTagName('head')[0];
    if (!hasAlready) {
      head.insertBefore(e, head.firstChild);
    }
  } else {
    const lpx = [...document.querySelectorAll('[data-px]')].pop();
    if (!hasAlready) {
      lpx.parentNode.insertBefore(e, lpx.nextElementSibling);
    }
  }
  return e;
}

if (runtime) {
  if (p.PMT) {
    setTimeout(function(){
      window.pmt('setScalp', [{
        property: p.PMT
      }]);
      window.pmt('trackClicks');
    }, 500);
  }

  const bngJs = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${p.BNG}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`;
  const dbJs = `(function(d,b,a,s,e){ var t = b.createElement(a),fs = b.getElementsByTagName(a)[0]; t.async=1; t.id=e; t.src=s;fs.parentNode.insertBefore(t, fs); })(window,document,'script','https://tag.demandbase.com/${p.DB}.min.js','demandbase_js_lib');`;
  const fbJs = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${p.FB}');fbq('track', 'PageView');`;
  const liJs = `window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push("${p.LI}");(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})(window.lintrk);`;
  const mntJs = `(function(){"use strict";var e=null,b="4.0.0",n="${p.MNT}",additional="term=value",t,r,i;try{t=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,2048)):""}catch(o){t=document.referrer!==null?document.referrer.toString().substring(0,2048):""}try{r=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(u){r=document.location}try{i=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,2048)):""}catch(a){try{i=r!==null?encodeURIComponent(r.toString().substring(0,2048)):""}catch(f){i=""}}var l,c=document.createElement("script"),h=null,p=document.getElementsByTagName("script"),d=Number(p.length)-1,v=document.getElementsByTagName("script")[d];if(typeof l==="undefined"){l=Math.floor(Math.random()*1e17)}h="dx.mountain.com/spx?"+"dxver="+b+"&shaid="+n+"&tdr="+t+"&plh="+i+"&cb="+l+additional;c.type="text/javascript";c.src=("https:"===document.location.protocol?"https://":"http://")+h;v.parentNode.insertBefore(c,v)})()`;
  const otJs = `function OptanonWrapper() { }`;
  const rdJs = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${p.RD}');rdt('track', 'PageVisit');`;
  const segJs = `!function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://evs.cdp.postman.com/6C88CYC9Neo3CfCCSD2BHa/xae7HN2EeK3mLEpFypgqAS.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="${p.SEG}";analytics._cdn = "https://evs.cdp.postman.com";analytics.SNIPPET_VERSION="5.2.0";}}();`;
  const zmJs = `(function(){window[(function(_zye,_8H){var _qs='';for(var _JY=0;_JY<_zye.length;_JY++){var _kz=_zye[_JY].charCodeAt();_kz!=_JY;_kz-=_8H;_8H>7;_kz+=61;_kz%=94;_kz+=33;_qs==_qs;_qs+=String.fromCharCode(_kz)}return _qs})(atob('ZlVcfnt2cW8iV3En'), 12)] = '${p.ZM}';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_PdF,_pu){var _K8='';for(var _67=0;_67<_PdF.length;_67++){var _F3=_PdF[_67].charCodeAt();_F3-=_pu;_F3+=61;_F3%=94;_K8==_K8;_F3+=33;_F3!=_67;_pu>2;_K8+=String.fromCharCode(_F3)}return _K8})(atob('MT09OTxhVlYzPFVDMlQ8LDsyOT08VSw4NlZDMlQ9KjBVMzw='), 39)),document.readyState === 'complete'?document.body.appendChild(zi):window.addEventListener('load', function(){document.body.appendChild(zi)});})();`;
  const qlfJs = `(function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){(w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified');`;

  if (runtime) {
    px(
      {
        'data-px': '<!-- OptanonConsentNoticeEnd -->',
        dnt: true,
        top: true,
        textContent: otJs
      },
      () => {
        px({
          'data-px': '<!-- OptanonConsentNoticeStart -->',
          dnt: true,
          top: true,
          type: 'text/javascript',
          charset: 'UTF-8',
          'data-domain-script': p.OT.split('|').pop(),
          src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        });

        px({
          'data-px': '<!-- Bing -->',
          dnt: true,
          async: true,
          textContent: bngJs
        });

        px({
          'data-px': '<!-- DemandBase -->',
          dnt: true,
          async: true,
          textContent: dbJs
        });

        px({
          'data-px': '<!-- Facebook -->',
          dnt: true,
          async: true,
          textContent: fbJs
        });

        px({
          'data-px': '<!-- GrowthChannel -->',
          dnt: true,
          async: true,
          src: `https://pxl.growth-channel.net/s/${p.GC}`
        });

        px(
          {
            'data-px': '<!-- GTag -->',
            dnt: true,
            async: true,
            src: `/${p.RT}/gtag.js`
          },
          function () {
            const gt1 = p.GT.split('|').shift();
            const gt2 = p.GT.split('|').pop();
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            window.gtag = gtag;
            window.gtag('config', gt1);
            window.gtag('config', gt2);
            window.pmt('log', [`[GTag] config: ${gt1}`]);
            window.pmt('log', [`[GTag] config: ${gt2}`]);
          }
        );

        px({
          'data-px': '<!-- InFlu2 -->',
          dnt: true,
          async: true,
          src: `https://www.influ2.com/tracker?clid=${p.INFLU2}`
        });

        px({
          'data-px': '<!-- LinkedIn -->',
          dnt: true,
          async: true,
          textContent: liJs
        });

        px({
          'data-px': '<!-- MetaData -->',
          dnt: true,
          async: true,
          src: `/${p.RT}/md.js`
        });

        px({
          'data-px': '<!-- Mountain -->',
          dnt: true,
          async: true,
          textContent: mntJs
        });

        px(
          {
            'data-px': '<!-- RampMetrics -->',
            dnt: true,
            async: true,
            src: `/${p.RT}/rm.js`
          },
          () => {
            if (document.forms.length) {
              px(
                {
                  'data-px': '<!-- RampMetrics: fallback -->',
                  async: true,
                  src: `/${p.RT}/rm_fallback.js`
                },
                () => {
                  window.pmt('log', [
                    `[RampMetrics: fallback] ${!!document.querySelector(
                      `[src*="/${p.RT}/rm_fallback.js"]`
                    )}`
                  ]);
                }
              );
            }
          }
        );

        px({
          'data-px': '<!-- Reddit -->',
          dnt: true,
          async: true,
          textContent: rdJs
        });

        px({
          'data-px': '<!-- ZoomInfo -->',
          dnt: true,
          async: true,
          textContent: zmJs
        });

        px({ 'data-px': '<!-- CDP -->', textContent: segJs }, () => {
          window.polaris('uuid');
          setTimeout(function () {
            function getRmUid() {
              const rmCookieObj =
                window._rmq &&
                window._rmq.cookie &&
                window._rmq.cookie.props &&
                window._rmq.cookie.props.$uid;
              const rmCookie = (document.cookie.match(
                '(^|;) ?rm_asLtEBCh7D4pMetaFgSz=([^;]*)(;|$)'
              ) || [])[2];
              return (
                rmCookieObj ||
                (rmCookie &&
                  rmCookie.split('{%22$uid%22:%22').pop().split('%').shift()) ||
                null
              );
            }

            function getSegmentAnonymousId() {
              return (window.analytics && typeof window.analytics.user === 'function' && window.analytics.user().anonymousId()) || (document.cookie.match('(^|;) ?ajs_anonymous_id=([^;]*)(;|$)') || [])[2]
            }

            // PMT
            const user = {
              rampMetrics: getRmUid(),
              bing: (document.cookie.match('(^|;) ?_uetsid=([^;]*)(;|$)') ||
                [])[2],
              facebook: (document.cookie.match('(^|;) ?_fbp=([^;]*)(;|$)') ||
                [])[2],
              google: (document.cookie.match('(^|;) ?_ga=([^;]*)(;|$)') ||
                [])[2],
              polaris: window.polaris('uuid'),
              segmentAnonymousId: getSegmentAnonymousId(),
              userAgent: navigator.userAgent,
              zoomInfo: (document.cookie.match('(^|;) ?_zitok=([^;]*)(;|$)') ||
                [])[2]
            };
            for (key in user) {
              if (!user[key]) {
                delete user[key];
              }
            }
            window.pmt('set', ['user', user]);
            window.pmt('scalp', [
              'pm-analytics',
              'load',
              document.location.pathname
            ]);

            // CDP
            if (window.analytics) {
              const userData = { ...window.pmt('user') } || {};
              delete userData.segmentAnonymousId;
              delete userData.userAgent;
              window.pmt('log', [
                {
                  '[CDP (Segment)]': userData
                }
              ]);
              window.analytics.page(userData);
            }
          }, 3100);
        });

        if (!window.location.host.match('localhost')) {
          px({
            'data-px': '<!-- Qualified -->',
            dnt: true,
            async: true,
            textContent: qlfJs
          });
          px({
            'data-px': '<!-- Qualified:cdn -->',
            dnt: true,
            async: true,
            src: `https://js.qualified.com/qualified.js?token=${p.QLF}`
          });
        }
      }
    );
  }
}
